import ApiService from "../../services/api.service";
import { useStore } from 'vuex';
import { Notify } from 'quasar';
import store from "..";

export const antennereferentiels = {
  state: {
    referentiels: [],
    referentielsCfa : [],
    referentielsAntenne: [],
    referentiel: null,
    objet: null
  },
  mutations: {
    setReferentiels(state, referentiels) {
      state.referentiels = referentiels
    },
    setReferentielsCfa(state, referentiels) {
      state.referentielsCfa = referentiels
    },
    setReferentielsAntenne(state, referentiels) {
      state.referentielsAntenne = referentiels
    },
    setReferentiel(state, referentiel) {
      state.referentiel = referentiel
    },
    callbackAddReferentiel(state, newReferentiel) {
      state.referentielsCfa.push(newReferentiel)
    },
    callbackEditReferentiel(state, payload) {
      state.referentielsCfa = state.referentielsCfa.map(referentiel => {
        if (referentiel.id === payload.id) {
          return payload;
        }
        return referentiel;
      });
    },
    callbackAddItem(state, payload) {
      state.referentielsAntenne.push(payload)
    },
    callbackRemove(state, payload) {
      state.referentielsAntenne = state.referentielsAntenne.filter(item => item.id !== payload.id)
    }
  },
  actions: {
    /* CRUD REPOSITORY/ACTION (POST/PATCH/DELETE) */
    //  Ajouter un référentiel vide
    async addAntenneReferentiel({ state }, formData) {

      // console.log('FORM DATA :');
      for (let pair of formData.entries()) {
        // console.log(pair[0]+ ': ' + pair[1]);
      }

      try {
        const response = await ApiService.postRequest('/antenne_referentiels', formData);
        // console.log(response.data)
        if (response.status === 201) {
          this.commit('callbackAddReferentiel', response.data);
          Notify.create({
            message: 'Le référentiel a été ajouté avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification du label du référentiel
    async editRepository(context, data) {
      try {
        const response = await ApiService.patchRequest(`/antenne_referentiels_edit_label`, data);
        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: data.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification de l'état ou editable : lock, unlock, archived, deleted
    async statusReferentiel(context, form) {
      try {
        const response = await ApiService.patchRequest(`/antenne_referentiels/${form.id}`, { editable: form.editable, etat: form.etat });
        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: form.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async deleteRepository(context, data) {
      try {
        const response = await ApiService.deleteRequest(`/antenne_referentiels/${data.id}`)
        if (response.status === 204) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: 'Référentiel supprimé avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  Ajouter une copie d'un référentiel
    async cloneAntenneReferentiel(context, formData) {
      try {
        let response;

        //  si le sous-module est référentiels
        if (formData.fromModule === 'referentiels') {
          response = await ApiService.postRequest('/antenne_referentiel_copy', formData);
        }

        //  si le sous-module est actions pédagogiques
        if (formData.fromModule === 'actions') {
          response = await ApiService.postRequest('/antenne_actions_copy', formData);
        }

        if (response.status === 200) {
          this.commit('callbackAddReferentiel', response.data)
          Notify.create({
            message: 'Le référentiel a été copié avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    /* CRUD COLLECTIONS (POST/PATCH/DELETE) */
    //  ajouter une unité de compétence, une compétence ou un descripteur
    async addEntity({ state }, formData) {
      try {
        if (formData.type === 'UNIT') {
         return await ApiService.postRequest('/antenne_referentiel_unite_competences', {
            label: formData.label,
            antenne_referentiel: '/antenne_referentiels/' + formData.id_parent,
          });
        }

        //  si le sous-module est référentiels
        if (formData.type === 'SKILL' && formData.fromModule === 'referentiels') {
          return await ApiService.postRequest('/antenne_referentiel_competences', {
            label: formData.label,
            unite_competences: '/antenne_referentiel_unite_competences/' + formData.id_parent,
            first_node: formData.firstNode,
          });
        }

        //  si le sous-module est actions pédagogiques
        if (formData.type === 'SKILL' && formData.fromModule === 'actions') {
          return await ApiService.postRequest('/antenne_referentiel_competences', {
            label: formData.label,
            antenne_referentiel: '/antenne_referentiels/' + formData.id_parent,
            first_node: formData.firstNode,
          });
        }

        if (formData.type === 'DESCRIPTOR') {
          return await ApiService.postRequest('/antenne_referentiel_descripteurs', {
            label: formData.label,
            competences: '/antenne_referentiel_competences/' + formData.id_parent,
          });
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    //  modifier une unité de compétence, une compétence ou un descripteur
    async editEntity(context, formData) {
      let response;

      try {
        if (formData.type === 'UNIT') {
          response = await ApiService.patchRequest(`/antenne_referentiel_unite_competences/${formData.id}`, {
            label: formData.label
          });
        }

        if (formData.type === 'SKILL') {
          response = await ApiService.patchRequest(`/antenne_referentiel_competences/${formData.id}`, {
            label: formData.label
          });
        }

        if (formData.type === 'DESCRIPTOR') {
          response = await ApiService.patchRequest(`/antenne_referentiel_descripteurs/${formData.id}`, {
            label: formData.label
          });
        }

        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data);
          Notify.create({
            message: formData.message,
            color: 'green'
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    async deleteEntity(context, formData) {
      let response;

      try {
        if (formData.type === 'UNIT') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_unite_competences/${formData.id}`)
        }

        if (formData.type === 'SKILL') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_competences/${formData.id}`)
        }

        if (formData.type === 'DESCRIPTOR') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_descripteurs/${formData.id}`)
        }

        if (response.status === 204) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: formData.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },

    // ***********  ADD LIAISON ANTENNE / RÉFÉRENTIEL / DIPLÔME
    async addAntenneReferentielDiplome(context, formData) {
      try {
        const response = await ApiService.postRequest('/antenne_referentiel_diplomes', formData);
        if (response.status === 201) {
          this.commit('setReferentielsAntenne', response.data);
          Notify.create({
            message: 'Le référentiel a été lié avec succès.',
            color: 'green'
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },

    async deleteAntenneReferentielDiplome(context, id) {
      try {
        const response = await ApiService.deleteRequest(`/antenne_referentiel_diplomes/${id}`);
        if (response.status === 204) {
          this.commit('callbackRemove', response.data);
          Notify.create({
            message: 'Le lien a été supprimé avec succès.',
            color: 'green'
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },






    /* RÉFÉRENTIEL / ACTIONS (GET) */
    fetchReferentiels(context) {
      //  * liste des référentiels filtrés en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?type=repository').then(
        response => {
          // console.log('Liste des référentiels : ', response.data['hydra:member'])
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    fetchReferentielByDiplome(context, idDiplome) {
      //  * liste des référentiels filtrés en amont par doctrine
      return ApiService.getRequest(`/antenne_referentiels_repositories?etat=0&diplome=${idDiplome}`).then(
        response => {
          context.commit('setReferentiel', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchActions(context) {
      //  * liste des actions pédagogiques filtrées en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?type=action').then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchAllRepositories(context) {
      //  * liste des actions pédagogiques filtrées en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?editable=false&etat=0').then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.err(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },

    // Récupérer les référentiels par CFA
    fetchReferentielsByCfa(context, cfaId) {
      return ApiService.getRequest(`/antenne_referentiels_cfa_repositories?cfa=${cfaId}`).then(
        response => {
          context.commit('setReferentielsCfa', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    async fetchReferentielById(context, idReferentiel) {
      return new Promise((resolve, reject) => {
        // console.log('idReferentiel', idReferentiel)
        ApiService.getRequest(`/antenne_referentiel_detail/${idReferentiel}`)
          .then(response => {
            // console.log('response.data', response.data)
            context.commit('setReferentiel', response.data);
            resolve();
          })
          .catch(error => {
            console.error('Erreur lors de la requête API:', error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    },
    // Récupérer les référentiels par antenne
fetchReferentielsByAntenna(context, antenneUuid) {
  // console.log(' - ', antenneUuid)
  return ApiService.getRequest(`/antenne_referentiel_diplomes?antenne=${antenneUuid}&referentiel.type=repository`)
    .then(response => {
      // Extraire les objets referentiel de chaque antenne_referentiel_diplomes, ajouter la propriété diplome et modifier le label
      const referentiels = response.data['hydra:member'].map(item => {
        const referentiel = {
          ...item.referentiel,
          id_link: item.id,
          diplome: item.diplome,
          action: item.action,
          name: item.referentiel.label,
        };

        if (item.diplome) {
          if (referentiel.diplome.complement_name) {
            let labelDiplome = referentiel.diplome.complement_name;

            if (referentiel.label && referentiel.label.toUpperCase() !== '') {
              labelDiplome += ' - ' + referentiel.label.toUpperCase();
            }
            referentiel.label = labelDiplome;
          }
        }

        if (item.action) {
          if (referentiel.action.name) {
            let labelAction = referentiel.action.name;

            if (referentiel.label && referentiel.label.toUpperCase() !== '') {
              labelAction += ' - ' + referentiel.label.toUpperCase();
            }
            referentiel.label = labelAction;
          }
        }

        return referentiel;
      });

      // console.log('Store Referentiels', referentiels);
      context.commit('setReferentielsAntenne', referentiels);
    })
    .catch(error => {
      console.error(error);
      Notify.create({
        message: "Une erreur s'est produite",
        color: "red"
      });
    });
},
    fetchReferentielsByAntennaAndReferentiel(context, { dispositifUuid, referentielUuid }) {
      // console.log('3.1 - ', dispositifUuid)
      // console.log('3.2 - ', referentielUuid)
      return ApiService.getRequest(`/antenne_referentiel_diplomes?antenne=${dispositifUuid}&referentiel=${referentielUuid}`).then(
        response => {

          // console.log('Store Referentiels', response.data['hydra:member']);
          context.commit('setReferentielsAntenne', response.data['hydra:member']);
          return response.data['hydra:member'];
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    }
  },
  getters: {
    getReferentiels(state) {
      return state.referentiels;
    },
    getReferentielsCfa(state) {
      return state.referentielsCfa;
    },
    getReferentielsAntenne(state) {
      return state.referentielsAntenne;
    },
    getReferentiel(state) {
      return state.referentiel;
    }
  }
}
