import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiant = {
  state: {
    etudiants: [],
    etudiant: null
  },
  mutations: {
    setEtudiants(state, etudiants) {
      state.etudiants = etudiants
    },
    setEtudiant(state, etudiant) {
      state.etudiant = etudiant
    }
  },
  actions: {
    /* ETUDIANTS (GET) */
    async fecthEtudiant(context, idStagiaire) {
      try {
        return await ApiService.getRequest(`/etudiants/${idStagiaire}`).then(
          response => {
            this.commit('setEtudiant', response.data);
            return response.data;
          });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    fetchEtudiants(context, filters) {
      if (filters !== undefined) {
        return ApiService.getRequest('/stagaires_pedagogie_light_list&' + filters).then(
          response => {
            context.commit('setEtudiants', response.data['hydra:member'])
          }
        )
          .catch( error => { console.error(error) } )
      } else {
        return ApiService.getRequest('/stagaires_pedagogie_light_list').then(
          response => {
            context.commit('setEtudiants', response.data['hydra:member'])
          }
        )
          .catch( error => { console.error(error) } )
      }
    },
    fetchEtudiantsByFilter(context, filters) {
      //todo:mettre la possibilité d'avoir plusieurs filtres
      //si ${filters} retourne [object, object]
      return ApiService.getRequest(`/stagaires_pedagogie_light_list?${Object.keys(filters)}=${Object.values(filters)}`)
        .then(response => {
          context.commit('setEtudiants', response.data['hydra:member']);
          return response.data['hydra:member'];
        })
        .catch( error => { console.error(error) } )
    },
    async fetchPifEtudiantById(context, idEtudiant) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/stagiaires_plan_individuel/${idEtudiant}`)
          .then(response => {
            context.commit('setEtudiant', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          })
      });
    },
    async fetchEvaluationEtudiantById(context, idEtudiant) {
      try {
        const response = await ApiService.getRequest(`/stagiaires_evaluations/${idEtudiant}`);
        if (response.status === 200) {
          context.commit('setEtudiant', response.data);
          Notify.create({
            message: "Les évaluations de l'étudiant ont été récupérées avec succès",
            color: 'green'
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors du chargement du stagiaire",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    /* CRUD ETUDIANTS (POST/PATCH/DELETE) */
    async editEtudiant({ state }, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.uri}`, formData);
        if (response.status === 200) {
          this.commit('setEtudiant', response.data);
          Notify.create({
            message: "La convention de l'étudiant a été modifiée avec succès",
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getEtudiants(state) {
      return state.etudiants;
    },
    getEtudiant(state) {
      return state.etudiant;
    }
  }
}
